.no-number-arrows::-webkit-inner-spin-button,
.no-number-arrows::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

.no-number-arrows {
  -moz-appearance: textfield;
}

.react-tel-input {
  .flag-dropdown {
    &.open {
      z-index: 3;
    }
  }
  .form-control {
    height: auto;
    width: 100%;
    font-size: 1rem;
    padding: 1rem 3rem;
  }
}
.req_star {
  z-index: 1;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #F44336;
  padding-right: calc(1.5em + 0.75rem);
}

.booking-comment {
  min-height: 80px!important;
}